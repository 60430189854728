import { graphql } from "gatsby"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import DefaultHero from "../components/default-hero"
import SidebarForm from '../components/sidebar-form'

import '../css/resource-center.css';

import YoastSeoParts from '../fragments/seo';

class ResourceCenterTemplate extends Component {

  render() {
    const page = this.props.data.wpPage;
    const { seo } = page;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <DefaultHero title={page.title} backgroundColor="#21c6be" className='resource-center-hero' />

        <div className="container baseContainerWrapper resource-center-wrapper">
          <div className="baseContainer">
            <div className="grid smallGrid">
              <div className="col-md-8 baseContainerLeft">
                <div className="grid" dangerouslySetInnerHTML={{ __html: page.content }}></div>
              </div>
              <div className="col-md-4 baseContainerRight">
                <SidebarForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default ResourceCenterTemplate

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      content
      uri
      id
      databaseId
      seo {
        ...seoParts
      }
    }
  }
`